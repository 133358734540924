<template>
  <div class="container">
    <PublicHeader :totalName="$t('newBe').title1" />
    <div class="head">
      <div class="item" v-for="item in headArr" :key="item">
        {{ item }}
      </div>
    </div>
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="line" v-for="(item, index) in list" :key="index">
          <div class="item">
            <div>
              <div>{{ getLocalTime(item.CreateTime).split(' ')[0] }}</div>
              <div>{{ getLocalTime(item.CreateTime).split(' ')[1] }}</div>
            </div>
          </div>
          <div class="item">
            <div>{{ item.ProductName }}</div>
          </div>
          <div class="item">
            <div>{{ item.BuyNum }}</div>
          </div>
          <div class="item">
            <div>
              {{
                item.ApplyStatus === 'Review'
                  ? $t('newBe').sqz
                  : item.WinningNum
              }}
            </div>
          </div>
          <div class="item">
            <div>
              <div>{{ getLocalTime(item.LaunchTime).split(' ')[0] }}</div>
              <div>{{ getLocalTime(item.LaunchTime).split(' ')[1] }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import productApi from '@/api/product'
import { getLocalTime } from '@/utils/validate'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      headArr: [
        this.$t('newBe').sgsj,
        this.$t('newBe').Currency,
        this.$t('newBe').Request,
        this.$t('newBe').Passing,
        this.$t('newBe').MarketTime
      ],
      list: [],
      form: {
        page: 1,
        size: 10
      },
      info: {},
      show: false,
      loading: false,
      finished: false
    }
  },
  methods: {
    getLocalTime,
    async getList() {
      const res = await productApi.getNewCoinRecords(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .head {
    display: flex;
    background: #17181e;
    .item {
      height: 44px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .content {
    margin-top: 10px;
    .list-box {
      height: calc(100vh - 64px);
      overflow: scroll;
    }
    .line {
      background: #17181e;
      display: flex;
      height: 60px;
      border-bottom: 1px solid #12151c;
      .item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.2;
      }
    }
  }
}
</style>
